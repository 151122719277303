import {
  DashboardDataTableDataType,
  LoadingState,
  OrgKey,
  TourIdentifier,
  ValueExpressionIdentifier,
} from '@data-table/data-table.types';
import { useDataTableCustomInfo } from '@data-table/hooks/use-data-table-custom-info-hook';
import { useDataTableValueExpressionData } from '@data-table/hooks/use-data-table-value-expression-data-hook';
import { useOrgNames } from '@data-table/hooks/use-org-names-hook';
import { useStandort } from '@data-table/hooks/use-standort-hook';
import { useTourList } from '@data-table/hooks/use-tour-list-hook';
import { escapeIdentifier } from '@data-table/utils';
import { Duration } from '@legacy-modules/dashboard/models/enums/Duration';
import OrgUnitNode from '@legacy-modules/metrics2/models/websocket/org/OrgUnitNode';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

type ValueExpressionDataTableData = [
  DashboardDataTableDataType[],
  (valueExpressionIdentifier: ValueExpressionIdentifier, filter: OrgKey[]) => number,
  (valueExpressionIdentifier: ValueExpressionIdentifier) => number,
  LoadingState
];
const useValueExpressionDataTableData = (
  orgKey: OrgKey,
  duration: Duration,
  orgTree: OrgUnitNode,
  valueExpressions: ValueExpression[] = [],
  isComparisonData: boolean = false,
  enabled: boolean = true
): ValueExpressionDataTableData => {
  const { isTour, showStandort } = useDataTableCustomInfo(orgKey, duration);

  const { uniqueTourIdentifiers, isLoading: isTourListLoading } = useTourList(
    orgKey,
    duration,
    isComparisonData,
    isTour
  );

  const orgNamesMap = useOrgNames(orgKey, orgTree);

  const standorte = useStandort(
    isTour
      ? Array.from(new Set(uniqueTourIdentifiers.map((tourIdentifier) => tourIdentifier.standortOrgKey)).keys())
      : [],
    showStandort
  );

  const orgKeys = isTour
    ? uniqueTourIdentifiers.map((tourIdentifier) => tourIdentifier.tourOrgKey)
    : Array.from(orgNamesMap.keys());

  const [valueExpressionData, getFilteredSumm, getRootOrgSumm, valueExpressionDataLoading] =
    useDataTableValueExpressionData(orgKey, orgKeys, valueExpressions, duration, isComparisonData, enabled);

  const data = (
    isTour
      ? uniqueTourIdentifiers.map((tourIdentifier) => ({
          tourIdentifier: {
            tourNumber: tourIdentifier.number,
            orgKey: tourIdentifier.tourOrgKey,
            orgId: tourIdentifier.organizationId,
            tourDate: tourIdentifier.date,
            contractorKey: tourIdentifier.contractorKey,
          } as TourIdentifier,
          standort: standorte.get(tourIdentifier.standortOrgKey),
          orgKey: tourIdentifier.tourOrgKey,
          orgName: '',
        }))
      : Array.from(orgNamesMap?.entries() || []).map(([subOrgKey, orgName]) => ({
          tourIdentifier: null,
          standort: null,
          orgKey: subOrgKey,
          orgName: orgName,
        }))
  ).map((row) => {
    valueExpressions.forEach((ve) => {
      row[escapeIdentifier(ve.identifier)] = valueExpressionData?.get(ve.identifier)?.get(row.orgKey);
    });
    return row;
  });

  return [data, getFilteredSumm, getRootOrgSumm, valueExpressionDataLoading || isTourListLoading];
};

export default useValueExpressionDataTableData;
