import {
  AggregationDateFilterInput,
  DateRangeAggregationInput,
  DateRangeGrouping,
  InputMaybe,
  KpisQuery,
  KpisQueryVariables,
  Scalars,
  useKpisQuery,
} from '@graphql-client/graphql';
import { ConfigKey, getEnvVariable } from '@legacy-modules/common/services/EnvironmentConfigurationService';
import { selectToken } from '@redux/auth.selectors';
import { useMemo } from 'react';
import { UseQueryOptions, UseQueryResult } from 'react-query';
import { useSelector } from 'react-redux';

let requestId = null;
export const generateRequestId = () => {
  if (requestId === null) {
    requestId = crypto.randomUUID();
  }
  return requestId;
};

/** Iterate through kpi values of results to filter out 'na' flagged */
export const KpiFilter = (kpiValue: { kpiId: string; value: string }) => kpiValue.value !== 'NA';
/** Parse as float, since we use numbers throughout the app */
export const KpiMapper = (kpiValue: { kpiId: string; value: string }) => ({
  ...kpiValue,
  value: parseFloat(kpiValue.value),
});
/** Map our internal frontend input variables to API type */
const InterfaceMapper = (internal: HookInputParameters): KpisQueryVariables => ({
  requestId: generateRequestId(),
  kpis: internal?.kpiIds?.map((element) => ({
    kpiId: element.id,
  })),
  dateFilter: internal.dateFilter,
  dateRangeAggregation: internal.dateRangeAggregation,
  dateRangeGrouping: internal.dateRangeGrouping,
  orgKeys: internal.orgKeys,
});

// type HookInputParameters = Omit<Rename<KpisQueryVariables, 'kpis', 'kpiIds'>, 'requestId'>;
type HookInputParameters = {
  kpiIds: { id: string }[];
  orgKeys: Array<Scalars['OrgKey']['input']> | Scalars['OrgKey']['input'];
  dateFilter: AggregationDateFilterInput;
  dateRangeAggregation?: InputMaybe<DateRangeAggregationInput>;
  dateRangeGrouping?: InputMaybe<DateRangeGrouping>;
};

export default function useKpiQuery<TData = KpisQuery, TError = unknown>(
  variables: HookInputParameters,
  options?: UseQueryOptions<KpisQuery, TError, TData>
): UseQueryResult<TData, TError> {
  const token = useSelector(selectToken);
  const parameters = InterfaceMapper(variables);
  const dataSource = useMemo<{ endpoint: string; fetchParams?: RequestInit }>(
    () => ({
      endpoint: getEnvVariable(ConfigKey.GQL_API_URL),
      fetchParams: {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
      },
    }),
    [token]
  );
  return useKpisQuery<TData, TError>(dataSource, parameters, options);
}
