import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { Button, ButtonGroup } from 'reactstrap';
import { MdChevronLeft, MdChevronRight, MdPermContactCalendar } from 'react-icons/md';
import TourQuickSelect from '../components/TourQuickSelect';
import TourList from '../models/entities/TourList';
import { TourIdentifier } from '../models/state/tourDetails/TourIdentifier';
import orderBy from 'lodash/orderBy';
import TourListEntry from '../models/entities/TourListEntry';
import { DateCompareSelector } from '../../navigation/components/DateCompareSelector';
import { WellKnownPermission } from '../../auth/constants/WellKnownPermission';
import { ContractorUtils } from '../../utils/tours/ContractorUtils';
import OrgNameDataProvider from '../../shipments/services/OrgNameDataProvider';
import { ContractorViewMode } from '../../navigation/constants/ContractorViewMode';
import { useAuthContext } from '@contexts/auth-context';
import { useTourList } from '@data-table/hooks/use-tour-list-hook';
import { Duration, DurationModes } from '@legacy-modules/dashboard/models/enums/Duration';
import { getOrgKeyByTourIdentifier } from '../helpers/GetOrgKeyByTourIdentifier';
import { useOrganizationData } from '@hooks/use-organization-data-hook';
import { useTourDetailsContext } from '@other-components/tour-details-context';
import { selectDashboardContractorViewMode, selectDashboardOrgKey } from '@redux/dashboard.selectors';
import {
  selectTourDetailsContractorKey,
  selectTourDetailsTourDate,
  selectTourDetailsTourIdentifier,
} from '@redux/tour-details.selectors';
import { tourDetailsSlice } from '@redux/tour-details.slice';

type Props = {};

const zeitverlaufBtnStyle = {
  width: '170px',
  paddingTop: '0.61rem',
  paddingBottom: '0.61rem',
};
const unterschriftenButtonStyle = {
  marginLeft: '15px',
  width: '170px',
  paddingTop: '0.61rem',
  paddingBottom: '0.61rem',
};
const buttonContainerStyles = {
  marginLeft: 'auto',
  marginRight: '15px',
};
const styles = {
  border: '1px solid var(--gray8)',
  borderRadius: '5px',
  marginRight: '20px',
  opacity: '0.8',
};

const orgNameDataProvider = new OrgNameDataProvider();

const TourDetailSubHeader: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const authService = useAuthContext();
  const orgKey = useSelector(selectDashboardOrgKey);
  const tourDate = useSelector(selectTourDetailsTourDate);
  const tourIdentifier = useSelector(selectTourDetailsTourIdentifier);
  const contractorViewMode = useSelector(selectDashboardContractorViewMode);
  const contractorKey = useSelector(selectTourDetailsContractorKey);

  const {
    signaturesState: [signatureIsOpen, setSignatureIsOpen],
    tourInTimeState: [tourInTimeOpen, setTourInTimeOpen],
  } = useTourDetailsContext();

  const { tourList: rawTourList } = useTourList(orgKey, {
    from: tourDate,
    to: tourDate,
    mode: DurationModes.Custom,
  } as Duration);

  const tourList = useMemo(() => {
    if (!rawTourList) return new TourList();
    return new TourList(rawTourList);
  }, [rawTourList]);

  const _onDateSelected = useCallback(
    (primaryRange: { from: moment.Moment; to: moment.Moment }) => {
      const { from } = primaryRange;
      dispatch(tourDetailsSlice.actions.setTourDate(from?.format('YYYY-MM-DD')));
    },
    [dispatch]
  );

  const toggleSignature = useCallback(() => {
    setSignatureIsOpen((signatureIsOpen) => !signatureIsOpen);
  }, [setSignatureIsOpen]);

  const selectTourCallback = useCallback(
    (tourIdentifier: TourIdentifier) => {
      dispatch(tourDetailsSlice.actions.setTourIdentifier(JSON.parse(JSON.stringify(tourIdentifier))));
      if (ContractorUtils.isContractor(orgKey)) {
        dispatch(tourDetailsSlice.actions.setContractorKey(orgKey));
      } else {
        dispatch(tourDetailsSlice.actions.setContractorKey(null));
      }
    },
    [dispatch, orgKey]
  );

  const toggle = useCallback(() => {
    setSignatureIsOpen(false);
    setTourInTimeOpen((tourInTimeOpen) => !tourInTimeOpen);
  }, [setSignatureIsOpen, setTourInTimeOpen]);

  const tourListOrdered = useMemo(
    () =>
      orderBy(
        tourList?.distinctTours,
        (o) => {
          return Number(o.identifier.number);
        },
        ['desc']
      ),
    [tourList?.distinctTours]
  );

  const onSelectQuickSelectOption = useCallback(
    (tourSelectionKey: string) => {
      const selectedTour = tourListOrdered.find((ti) => {
        return ti.identifier.number === tourSelectionKey;
      });
      selectTourCallback(selectedTour.identifier);
    },
    [selectTourCallback, tourListOrdered]
  );

  const _onSelectSibling = useCallback(
    (offset: number) => {
      let index = tourListOrdered.findIndex((t) => t.identifier.number === tourIdentifier.number) + offset;
      if (index < 0) {
        index = tourListOrdered.length - 1;
      } else if (index > tourListOrdered.length - 1) {
        index = 0;
      }
      const tourlistEntry = tourListOrdered[index];
      if (!tourlistEntry) {
        return;
      }
      selectTourCallback(tourlistEntry.identifier);
    },
    [selectTourCallback, tourIdentifier, tourListOrdered]
  );

  const [orgIdNameMap, setOrgIdNameMap] = useState<{
    [key: string]: string;
  }>({});

  useEffect(() => {
    const keys = tourList?.distinctTours
      ?.filter((t) => t?.identifier?.orgId != null)
      .map((t) => `oz:${t.identifier.orgId}`);
    orgNameDataProvider.query(keys).then(setOrgIdNameMap);
  }, [tourList]);

  const formatIdentifier = useCallback(
    (t: TourListEntry) => {
      // Add org name on task force tours
      if (t?.contractorKey && ContractorUtils.isContractor(orgKey)) {
        return `Tour ${t.identifier.number} (${orgIdNameMap?.[`oz:${t.identifier.orgId}` || t.identifier.orgId]})`;
      }
      return `Tour ${t?.identifier?.number || 'unbekannt'}`;
    },
    [orgIdNameMap, orgKey]
  );

  const tourQuickSelectionOptions = useMemo(
    () =>
      (tourList?.distinctTours || [])
        .filter((t) => {
          if (!ContractorUtils.isContractor(orgKey) || contractorViewMode === ContractorViewMode.All) {
            return true;
          }
          if (contractorViewMode === ContractorViewMode.Home) {
            return ContractorUtils.isHomeTour(t?.contractorKey);
          }
          if (contractorViewMode === ContractorViewMode.TaskForce) {
            return !ContractorUtils.isHomeTour(t?.contractorKey);
          }
          return false;
        })
        .map((t: TourListEntry) => {
          return {
            value: t.identifier.number,
            label: formatIdentifier(t),
          };
        }),
    [contractorViewMode, formatIdentifier, orgKey, tourList?.distinctTours]
  );

  const selectedEntry = tourList?.distinctTours?.find(
    (t) => t.identifier.orgId === tourIdentifier.orgId && t.identifier.number === tourIdentifier.number
  );

  const primaryRange = { from: tourDate, to: tourDate };
  const allowTourReport = authService.can(WellKnownPermission.TourReport);
  const allowDateSelection = authService.can(WellKnownPermission.TourAllowDateSelection);
  const [onNextClickCallback, onPrevClickCallback] = useMemo(
    () =>
      tourList?.distinctTours?.length > 1 ? [() => _onSelectSibling(-1), () => _onSelectSibling(1)] : [null, null],
    [_onSelectSibling, tourList?.distinctTours?.length]
  );

  const orgKeys = useMemo(
    () => [getOrgKeyByTourIdentifier(tourIdentifier, contractorKey)],
    [tourIdentifier, contractorKey]
  );
  const [orgDataMap] = useOrganizationData(orgKeys, ['breadcrumb']);
  const orgUnit = orgDataMap?.get(orgKeys[0]);
  const signatureButtonDisabled = !authService.canAccessStop(orgUnit?.breadcrumb);

  const buttonStyle = signatureIsOpen
    ? {
        backgroundColor: 'var(--text-error-color)',
        color: 'white',
        border: 'var(--text-error-color)',
      }
    : {
        backgroundColor: 'var(--primary)',
        color: 'white',
        border: 'var(--primary)',
      };

  return (
    <React.Fragment>
      <div>
        {tourList?.distinctTours?.length > 0 ? (
          <div style={{ marginRight: 20 }}>
            <TourQuickSelect
              onPrevClick={onPrevClickCallback}
              onNextClick={onNextClickCallback}
              onOptionClick={onSelectQuickSelectOption}
              selectionName={formatIdentifier(selectedEntry)}
              options={tourQuickSelectionOptions}
            />
          </div>
        ) : (
          <ButtonGroup style={styles}>
            <Button color='white' disabled>
              <MdChevronLeft size={20} />
            </Button>
            <Button style={{ width: '93px', height: '37px' }} color='white' disabled>
              Tour...
            </Button>
            <Button color='white' disabled>
              <MdChevronRight size={20} />
            </Button>
          </ButtonGroup>
        )}
      </div>

      <div>
        {allowDateSelection ? (
          <DateCompareSelector
            comparisonEnabled={true}
            disabled={!!tourInTimeOpen}
            dayOnly
            onSelectionChanged={_onDateSelected}
            primaryRange={primaryRange}
            usePlainDateDisplay={true}
          />
        ) : (
          <ButtonGroup style={{ height: 40 }}>
            <Button
              outline
              color='secondary'
              style={{
                paddingLeft: 18,
                paddingRight: 4,
                flex: 1,
                textAlign: 'left',
                cursor: 'default',
              }}>
              <MdPermContactCalendar size={18} />
              <span
                style={{
                  marginLeft: 12,
                  textOverflow: 'ellipsis',
                  display: 'inline-block',
                  overflowX: 'hidden',
                  verticalAlign: 'bottom',
                  width: 80,
                }}>
                Heute
              </span>
            </Button>
          </ButtonGroup>
        )}
      </div>

      <div style={buttonContainerStyles}>
        {
          <Button style={zeitverlaufBtnStyle} color={tourInTimeOpen ? 'danger' : 'primary'} onClick={toggle}>
            Zeitverlauf {tourInTimeOpen ? 'schließen' : 'anzeigen'}
          </Button>
        }
        {allowTourReport && (
          <Button
            style={{
              ...unterschriftenButtonStyle,
              ...buttonStyle,
            }}
            disabled={signatureButtonDisabled}
            onClick={toggleSignature}>
            Unterschriften
          </Button>
        )}
      </div>
    </React.Fragment>
  );
};

export default TourDetailSubHeader;
