import React from 'react';
import { DataTableBody } from '@data-table/components/data-table-body';
import { DataTableColumnHeaders } from '@data-table/components/data-table-column-headers';
import { DataTableFooter } from '@data-table/components/data-table-footer';
import { ValueExpressionTableHeader } from '@data-table/components/data-table-header';
import {
  DataTableLoadingPlaceholder,
  DataTableNoDataPlaceholder,
} from '@data-table/components/data-table-placeholders';
import { DashboardDataTableDataType, DataTableConfigProps } from '@data-table/data-table.types';
import { useDashboardDataTableColumns } from '@data-table/hooks/use-dashboard-data-table-columns-hook';
import { useDataTableCustomInfo } from '@data-table/hooks/use-data-table-custom-info-hook';
import { useDataTableDuration } from '@data-table/hooks/use-data-table-duration-hook';
import { useDataTableOrgKey } from '@data-table/hooks/use-data-table-org-key-hook';
import { useDataTableValueExpressionsState } from '@data-table/hooks/use-data-table-value-expressions-state-hook';
import { useValueExpressionDataTableData } from '@data-table/hooks/use-value-expression-data-table-data-hook';
import { Duration } from '@legacy-modules/dashboard/models/enums/Duration';
import { useDataTableCellColoring } from '@data-table/hooks/use-data-table-cell-coloring-hook';

export type DashboardDataTableConfigProps = Omit<
  DataTableConfigProps<DashboardDataTableDataType>,
  'data' | 'columns' | 'isTour'
> & {
  defaultDuration?: Duration;
  defaultValueExpressionKeys?: string[];
  availableValueExpressionKeys?: string[];
};

const withDashboardDataTableConfig =
  (WrappedComponent: React.FC<DataTableConfigProps<DashboardDataTableDataType>>) =>
  ({
    componentId,
    defaultDuration,
    defaultValueExpressionKeys,
    availableValueExpressionKeys,
    header,
    defaultSorting,
    ...rest
  }: DashboardDataTableConfigProps) => {
    const [durationIsLoading, duration, onDurationChange] = useDataTableDuration(componentId, defaultDuration);

    const [hasCellColoring] = useDataTableCellColoring(componentId);

    const [orgKey, orgTree] = useDataTableOrgKey();

    const { isTour, isToday, showFortschritt, showStandort, title } = useDataTableCustomInfo(orgKey, duration);

    const [valueExpressions, valueExpressionKeys, onColumnAdd, onColumnRemove, valueExpressionsLoading] =
      useDataTableValueExpressionsState(componentId, isToday && isTour, defaultValueExpressionKeys);

    const [data, getFilteredSumm, getRootOrgSumm, dataIsLoading] = useValueExpressionDataTableData(
      orgKey,
      duration,
      orgTree,
      valueExpressions,
      false,
      !durationIsLoading && !valueExpressionsLoading
    );

    const [comparisonData, getComparisonFilteredSumm, getComparisonRootOrgSumm, comparisonDataIsLoading] =
      useValueExpressionDataTableData(
        orgKey,
        duration,
        orgTree,
        valueExpressions,
        true,
        !durationIsLoading && !valueExpressionsLoading
      );

    const columns = useDashboardDataTableColumns(
      orgKey,
      duration,
      valueExpressions,
      hasCellColoring,
      getFilteredSumm,
      getRootOrgSumm
    );

    const comparisonColumns = useDashboardDataTableColumns(
      orgKey,
      duration,
      valueExpressions,
      false,
      getComparisonFilteredSumm,
      getComparisonRootOrgSumm
    );

    const loading = durationIsLoading || valueExpressionsLoading || dataIsLoading || comparisonDataIsLoading;
    const dataAvailable = !!data.length;

    return (
      <WrappedComponent
        componentId={componentId}
        data={data}
        columns={columns}
        state={{
          columnVisibility: {
            orgKey: false,
            orgName: !isTour,
            tourIdentifier: isTour,
            standort: showStandort,
            fortschritt: showFortschritt,
          },
          columnPinning: {
            left: ['tourIdentifier', 'orgName', 'standort', 'fortschritt'],
          },
        }}
        header={
          header || (
            <ValueExpressionTableHeader
              title={title}
              duration={duration}
              valueExpressionKeys={valueExpressionKeys}
              availableKpiSelectorKeys={availableValueExpressionKeys}
              disabled={!dataAvailable}
              loading={loading}
              onDurationChange={onDurationChange}
              onColumnRemove={onColumnRemove}
              onColumnAdd={onColumnAdd}
            />
          )
        }
        body={
          <>
            {loading && <DataTableLoadingPlaceholder />}
            {!loading && !dataAvailable && <DataTableNoDataPlaceholder />}
            {!loading && dataAvailable && (
              <DataTableBody columnHeader={<DataTableColumnHeaders />} footer={<DataTableFooter />} />
            )}
          </>
        }
        comparisonData={comparisonData}
        comparisonColumns={comparisonColumns}
        defaultSorting={
          defaultSorting ?? isTour ? [{ id: 'tourIdentifier', desc: true }] : [{ id: 'orgName', desc: true }]
        }
        {...rest}
      />
    );
  };

export default withDashboardDataTableConfig;
